import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
   state: {
      status: '',
      token: localStorage.getItem('token') || '',
      user : {}
   },
   mutations: {
      auth_success(state, data) {
         console.log (data)
         state.status = 'success'
         state.token = data.token
         localStorage.setItem('token', data.token)
         //state.user = { 'email': data.email, 'email': data.email, 'zones': data.zonas, 'selectedZone': data.zonas[0] }
         state.user = { 'usuario': data.usuario, 'rol': data.rol, 'id': data.id }
      },
      auth_fail(state) {
         state.status = ''
         state.token = ''
         localStorage.clear()
         localStorage.removeItem('vuex')
         state.user = {}
      }
   },
   actions: {
      login({commit}, params) {
         return new Promise((resolve, reject) => {
            axios({url: 'https://api.asein.com/auth' + '?usuario=' + params.usuario + '&pass=' + params.password, method: 'POST' })
            .then(resp => {
               if (resp.data.res == "success") {
                  commit('auth_success', resp.data)
               } else {
                  commit('auth_fail')
               }
               resolve(resp)       
            })
            .catch(err => {
               localStorage.removeItem('token')
               console.log(err)
               reject(err)
            })
         })
      },
      logout({commit}) {
         commit('auth_fail')
      }
   },
   getters : {
      isLoggedIn: state => !!state.token,
      authStatus: state => state.status,
   },
   plugins: [createPersistedState()]
})