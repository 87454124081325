<template>
   <div id="login">

      <nav-bar></nav-bar>
      
      <div class="sidenav"></div>
         <div class="main">
            <b-col cols="6">
               <div class="login-form">
                  <b-form @submit.prevent="tryToLogIn">
                     <b-form-group :label="$t('user')">
                        <b-form-input v-model="form.usuario" type="text" :placeholder="$t('user-place')" required></b-form-input>
                     </b-form-group>
                     <b-form-group class="mt-3" :label="$t('pass')">
                        <b-form-input v-model="form.password" type="password" :placeholder="$t('pass-place')" required></b-form-input>
                     </b-form-group>
                     <b-button class="mt-3" style="float: right;" type="submit" variant="danger">{{ $t('enviar') }}</b-button>
                  </b-form>
               </div>
            </b-col>

            <b-row v-if="isAuthError">
               <b-col class="mt-2">
                  <b-alert variant="danger" show>{{ authError }}</b-alert>
               </b-col>
            </b-row>
      </div>
   </div>
</template>

<script>
   import { authMethods } from "@/services/helpers"
   import NavBar from '@/components/NavBar.vue'
   export default {
      name: 'LoginView',
      data() {
         return {
            form: {
               usuario: '',
               password: ''
            },
            isAuthError: false,
            authError: ''
         }
      },
      components: {
         ...authMethods,
         NavBar
      },
      methods: {
         tryToLogIn() {
            this.tryingToLogIn = true
            this.authError = null
            this.$store.dispatch('login', this.form)
            .then((resp) => {
               if (resp.data.res == 'success') {
                  this.isAuthError = false
                  this.authError = null
                  this.$router.push('/')
               } else {
                  this.authError = this.$t('error-login')
                  this.isAuthError = true
               }
               this.tryingToLogIn = false
            })
            .catch(err => console.log(err))
         }
      }
   }
</script>

<style scoped>
   body {
      font-family: "Lato", sans-serif;
   }
   .main-head{
      height: 150px;
      background: #FFF;
      
   }
   .sidenav {
      height: 100%;
      background-image: url('/public/assets/img/bg-login.jpg');
      background-size: contain;
      background-repeat: no-repeat;
      overflow-x: hidden;
      padding-top: 20px;
   }
   .main {
      padding: 0px 10px;
   }
   @media screen and (max-height: 450px) {
      .sidenav {padding-top: 15px;}
   }
   @media screen and (max-width: 450px) {
      .login-form{
         margin-top: 10%;
      }
      .register-form{
         margin-top: 10%;
      }
   }
   @media screen and (min-width: 768px){
      .main{
         margin-left: 50%; 
      }
      .sidenav{
         width: 45%;
         position: fixed;
         z-index: 1;
         top: 0;
         left: 0;
      }
      .login-form{
         margin-top: 80%;
      }
      .register-form{
         margin-top: 20%;
      }
   }
   .login-main-text{
      margin-top: 20%;
      padding: 60px;
      color: #fff;
   }
   .login-main-text h2{
      font-weight: 300;
   }
   .btn-black{
      background-color: #000 !important;
      color: #fff;
   }
   .alert.alert-danger {
      padding: 6px;
      margin-top: 8px;
   }
</style>