<template>
   <b-container fluid class="mt-5">
      <b-row class="mb-5">
         <b-col cols="3">
            <h1>Listado Usuarios</h1>
         </b-col>
         <b-col cols="9">
            <b-button class="mt-2 mx-2" @click="$router.push('/')">Volver al buscador</b-button>
         </b-col>
      </b-row>

      <div>
         <b-card no-body>
            <b-tabs class="py-3 px-4" pills card vertical>
               <b-tab title="Usuarios" active><b-card-text><tabla-usuarios></tabla-usuarios></b-card-text></b-tab>
               <b-tab title="Representantes"><b-card-text><tabla-repres></tabla-repres></b-card-text></b-tab>
               <!-- <b-tab title="Distribuidores"><b-card-text><tabla-distribuidores></tabla-distribuidores></b-card-text></b-tab> -->
            </b-tabs>
         </b-card>
      </div>
   
   </b-container>
</template>

<script>
   import TablaUsuarios from '@/views/Usuarios/Usuarios.vue'
   import TablaRepres from '@/views/Usuarios/Representantes.vue'
   //import TablaDistribuidores from '@/views/Usuarios/Distribuidores.vue'
   export default {
      name: 'UsersView',
      components: { TablaUsuarios, TablaRepres }
   }
</script>