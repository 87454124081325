<template>
   <div class="mx-5">
      <b-container class="bv-example-row bv-example-row-flex-cols">
         <b-row align-h="end">
            <b-col cols="2">
               <b-button block size="sm" @click="nuevoUsuario()">Nuevo Usuario</b-button>
            </b-col>
         </b-row>
      </b-container>
      <b-table class="mt-4" :items="usuarios" :fields="fields" stacked="md" show-empty small>
         <template #cell(name)="row">
            {{ row.value.first }} {{ row.value.last }}
         </template>
      
         <template #cell(actions)="row">
            <b-button size="sm" @click="eliminarUsuario(row.item.id, row.item.usuario)" style="margin-left:10px;">Eliminar</b-button>
         </template>
      
         <template #row-details="row">
            <b-card>
               <ul>
                  <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
               </ul>
            </b-card>
         </template>
      </b-table>

      <b-modal id="modal-nuevo-usuario" ref="modal" title="Nuevo Usuario" @show="resetModal" @hidden="resetModal" @ok="handleOk">
         <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Usuario:" label-for="name-input" invalid-feedback="El campo es requerido">
               <b-form-input type="text" v-model="usuario" required></b-form-input>
            </b-form-group>
            <b-row class="mt-3">
               <b-col sm="9">
                  <b-form-group label="Nuevo password:" label-for="password-input" invalid-feedback="El campo es requerido" >
                     <b-form-input type="text" v-model="newPass" required></b-form-input>
                  </b-form-group>
                  <p v-if="msg.length > 0" class="danger">{{ msg }}</p>
               </b-col>
               <b-col sm="3">
                  <b-form-group style="color:#fff;" label="Generar">
                     <b-button class="btn-pass" @click="generarPass()">Generar</b-button>
                  </b-form-group>
               </b-col>
            </b-row>
            <b-form-group class="mt-3" label="Distribuidor:" label-for="name-input" invalid-feedback="El campo es requerido">
               <b-form-input type="text" v-model="distribuidor" required readonly></b-form-input>
            </b-form-group>
            <b-form-group v-if="repres.length > 0" class="mt-3" label="Representante:" label-for="name-input" invalid-feedback="El campo es requerido">
               <b-form-select class="select-asein mb-3" v-model="representante" :options="repres" value-field="representante" text-field="representante"></b-form-select>
            </b-form-group>
         </form>
    </b-modal>

   </div>
</template>

<script>
   import axios from 'axios'
   export default {
      name: 'UsuariosTableView',
      data() {
         return {
            usuarios: [],
            repres: [],
            totalRows: 1,
            fields: [
               { key: 'id', label: 'ID', sortable: false, sortDirection: 'asc' },
               { key: 'usuario', label: 'Usuario', sortable: false, sortDirection: 'asc' },
               { key: 'propietario', label: 'Distribuidor', sortable: false, sortDirection: 'asc' },
               { key: 'representante', label: 'Representante', sortable: false, sortDirection: 'asc' },
               { key: 'actions', label: 'Acciones', sortable: false }
            ],
            modalEliminar: '',
            nameState: null,
            passState: null,
            usuario: '',
            newPass: '',
            rol: 0,
            distribuidor: 'ASEIN',
            representante: null,
            msg: ''
         }
      },
      mounted () {
         this.cargarUsuarios ()
         this.cargarRepres ()
         this.totalRows = this.usuarios.length
      },
      methods: {
         cargarUsuarios () {
            axios({url: 'https://api.asein.com/cargar-usuarios', method: 'POST' })
            .then(resp => {
               this.usuarios = resp.data.usuarios
            })
            .catch(err => {
               console.log(err)
            })
         },
         cargarRepres () {
            axios({url: 'https://api.asein.com/cargar-repres', method: 'POST' })
            .then(resp => {
               this.repres = resp.data.repres
            })
            .catch(err => {
               console.log(err)
            })
         },
         nuevoUsuario () {
            this.cargarRepres()
            this.$bvModal.show('modal-nuevo-usuario')
         }, 
         generarPass () {
            const abc = "a b c d e f g h j k m n p q r s t u v w x y z 1 2 3 4 5 6 7 8 9 A B C D E F G H J K M N O P Q R S T U V W X Y Z".split(" ");
            let resultado = ''
            let i = 0
            for(i=0;i<=8;i++) {
               const random = Math.floor(Math.random() * abc.length);
               resultado += abc[random]
            }
            this.newPass = resultado
         },
         eliminarUsuario (id, usuario) {
            this.modalEliminar = ''
            this.$bvModal.msgBoxConfirm('¿Está seguro que desea eliminar el usuario ' + usuario + '?', {
               title: 'Confirmar borrado',
               size: 'sm',
               buttonSize: 'sm',
               okVariant: 'danger',
               okTitle: 'Eliminar',
               cancelTitle: 'Cancelar',
               footerClass: 'p-2',
               centered: true
            })
            .then(value => {
               if (value == true) {
                  axios({url: 'https://api.asein.com/eliminar-usuario?id=' + id, method: 'POST' })
                  .then(resp => {
                     console.log (resp.data)
                     this.cargarUsuarios ()
                  })
                  .catch(err => {
                     console.log(err)
                  })
               }
            })
            .catch(err => {
               console.log (err)
            })
         },
         checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            //this.passState = valid
            return valid
         },
         resetModal() {
            this.usuario = ''
            this.newPass = ''
            this.representante = null
            this.msg = ''
            //this.nameState = null
            //this.passState = null
         },
         handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
         },
         handleSubmit() {
            if (this.newPass.length < 4) {
               this.msg = "La contraseña es demasiado corta"
               return
            }
            if (!this.checkFormValidity()) {
               return
            }
            axios({url: 'https://api.asein.com/nuevo-usuario?usuario=' + this.usuario + '&password=' + this.newPass + '&rol=' + this.rol + '&propietario=' + this.distribuidor + '&representante=' + this.representante, method: 'POST' })
               .then(resp => {
                  console.log (resp.data)
                  this.cargarUsuarios ()
               })
               .catch(err => {
                  console.log(err)
               })
            this.$nextTick(() => {
               this.$bvModal.hide('modal-nuevo-usuario')
            })
         }
      }
   }
</script>

<style scoped>
   .select-asein {
      height: calc(1.5em + .75rem + 2px);
   }
   .btn-pass {
      margin-top: -7px;
   }
   .danger {
      color: red;
      font-size: 14px;
   }
</style>