<template>
   <b-modal id="modalFiguras" ref="modalFiguras" title="Figuras Escobillas" size="xl" ok-only ok-variant="danger" ok-title="Cerrar">
      <b-container>
         <b-row>
            <b-col cols="12" sm="2" class="my-1" :key="index" v-for="(item, index) in paginatedItems">
               <a @click="selFigura(item.num_figura)" class="seleccionar-figura">
                  <img :src="item.imagen" :alt="item.imagen" width="60"/>{{ item.figura }}
               </a>
            </b-col>
         </b-row>

         <b-row align-v="end" class="mt-4" style="float: right;">
            <b-col md="6" class="my-1">
               <b-pagination @change="onPageChanged" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
            </b-col>
         </b-row>
      </b-container>
   </b-modal>
</template>

<script>
   import axios from 'axios'
export default {
   name: "ModalFiguras",
   data() {
      return {
         items: [],
         paginatedItems: [],
         currentPage: 1,
         perPage: 18,
      };
   },
   methods: {
      paginate(page_size, page_number) {
         let itemsToParse = this.items;
         this.paginatedItems = itemsToParse.slice(page_number * page_size, (page_number + 1) * page_size);
      },
      onPageChanged(page) {
         this.paginate(this.perPage, page - 1);
      },
      selFigura(value) {
         let returnData = {
            figura: ''
         };
         returnData['figura'] = value
         this.$emit("getFigura", returnData);
         this.$refs['modalFiguras'].hide()
      }
   },
   computed: {
      totalRows () {
         return this.items.length
      }
   },
   async mounted() {
      this.paginate(this.perPage, 0);
      await axios({url: 'https://api.asein.com/cargar-figuras', method: 'POST' })
            .then(result => {
               this.items = result.data['items']
               for (var i = 0; i < this.perPage; i++) {
                  this.paginatedItems.push(this.items[i])
               }
            })
            .catch(err => {
               console.log(err)
            })
   }
};
</script>

<style scoped>
   .seleccionar-figura {
      cursor: pointer;
   }
</style>