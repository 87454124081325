<template>
   <b-row align-h="end">
      <b-col cols="4">
         <b-nav text-variant="white" style="float: right;">
            <b-nav-item class="lang" :class="{ active: langActive('es'), txt_black: txtBlack() }">
               <a @click="$i18n.locale='es'">ESP</a>
            </b-nav-item>
            <b-nav-item class="lang" :class="{ active: langActive('en'), txt_black: txtBlack() }">
               <a @click="$i18n.locale='en'">ENG</a>
            </b-nav-item>
            <b-nav-item class="lang" :class="{ active: langActive('it'), txt_black: txtBlack() }">
               <a @click="$i18n.locale='it'">ITA</a>
            </b-nav-item>
            <b-nav-item class="lang" :class="{ active: langActive('fr'), txt_black: txtBlack() }">
               <a @click="$i18n.locale='fr'">FRA</a>
            </b-nav-item>
         </b-nav>
      </b-col>

      <b-col v-if="this.$store.state.status == 'success'" cols="2">
         <b-nav text-variant="white">
            <b-nav-item-dropdown :text="usuario" toggle-class="nav-link-custom" right>
               <b-dropdown-item v-if="isAdmin()"><router-link to="/usuarios">Gestionar usuarios</router-link></b-dropdown-item>
               <b-dropdown-item v-b-modal.modal-nuevo-pass>{{ $t('camb-pass')}}</b-dropdown-item>
               <a href="javascript:void(0)" @click="logout()"><b-dropdown-item>{{ $t('cerrar-sesion') }}</b-dropdown-item></a>
            </b-nav-item-dropdown>
         </b-nav>
      </b-col>

      <b-modal id="modal-nuevo-pass" ref="modal" title="Cambiar password" @show="resetModal" @hidden="resetModal" @ok="handleOk">
         <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Nuevo password" label-for="password-input" invalid-feedback="El campo es requerido">
               <b-form-input id="password-input" type="password" v-model="newPass" required></b-form-input>
            </b-form-group>
            <p v-if="msg.length > 0" class="danger">{{ msg }}</p>
            <b-form-group class="mt-3" label="Confirmar password" label-for="confirm-input" invalid-feedback="El campo es requerido">
               <b-form-input id="confirm-input" type="password" v-model="confirmPass" required></b-form-input>
            </b-form-group>
            <p v-if="msg.length > 0" class="danger">{{ msg }}</p>
         </form>
    </b-modal>

   </b-row>
</template>

<script>
   import axios from 'axios'
   export default {
      name: 'NavBarComponent',
      data () {
         return {
            newPass: '',
            confirmPass: '',
            msg: '',
         }
      },
      methods: {
         isAdmin () {
            if (this.$store.state.user.rol > 0) {
               return true
            } else {
               return false
            }
         },
         logout() {
            this.$store.dispatch('logout')
            this.$router.go()
            this.$router.push('/login')
         },
         resetModal() {
            this.newPass = ''
            this.confirmPass = '',
            this.msg = ''
         },
         handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.cambiarPassword()
         },
         cambiarPassword() {
            if (this.newPass != this.confirmPass) {
               this.msg = 'Las contraseñas no coinciden'
               return
            }
            if (this.newPass.length < 4) {
               this.msg = 'La contraseña es demasiado corta'
               return
            }
            axios({url: 'https://api.asein.com/cambiar-password?id=' + this.id + '&newPass=' + this.newPass, method: 'POST' })
               .then(resp => {
                  if (resp.data.update == true) {
                     console.log ("Password cambiado correctamente")
                  }
               })
               .catch(err => {
                  console.log(err)
            })
            this.$nextTick(() => {
               this.msg = ''
               this.$bvModal.hide('modal-nuevo-pass')
            })
         },
         langActive(lang) {
            if (this.$i18n.locale == lang) {
               return true
            }
         },
         txtBlack () {
            if (this.$router.currentRoute.name == 'login') {
               return true
            } else {
               return false
            }
         }
      },
      computed: {
         usuario () {
            return this.$store.state.user.usuario
         },
         id () {
            return this.$store.state.user.id
         }
      }
   }
</script>

<style scoped>
   a {
      text-decoration: none;
      color: #000;
   }
   .danger {
      color: red;
      font-size: 14px;
   }
   a.nav-link {
      color: #fff;
   }
   .nav-item.lang.active a {
      color: red;
   }
   .lang.txt_black a {
      color: #000;
   }
</style>