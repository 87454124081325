<template>
   <div class="mx-5">
      <b-container class="bv-example-row bv-example-row-flex-cols">
         <b-row align-h="end">
            <b-col cols="2">
               <b-button block size="sm" v-b-modal.modal-nuevo-repre>Nuevo Repre</b-button>
            </b-col>
         </b-row>
      </b-container>
      <b-table class="mt-4" :items="repres" :fields="fields" stacked="md" show-empty small>
         <template #cell(name)="row">
            {{ row.value.first }} {{ row.value.last }}
         </template>
      
         <template #cell(actions)="row">
            <b-button size="sm" @click="eliminarRepre(row.item.id, row.item.representante)" style="margin-left:10px;">Eliminar</b-button>
         </template>
      
         <template #row-details="row">
            <b-card>
               <ul>
                  <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
               </ul>
            </b-card>
         </template>
      </b-table>

      <b-modal id="modal-nuevo-repre" ref="modal" title="Nuevo Representante" @show="resetModal" @hidden="resetModal" @ok="handleOk">
         <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Nombre Representante" label-for="name-input" invalid-feedback="El campo es requerido" :state="nameState">
               <b-form-input id="name-input" v-model="nombre" :state="nameState" required></b-form-input>
            </b-form-group>
         </form>
    </b-modal>

   </div>
</template>

<script>
   import axios from 'axios'
   export default {
      name: 'RepresTableView',
      data() {
         return {
            repres: [],
            totalRows: 0,
            fields: [
               { key: 'id', label: 'ID', sortable: false, sortDirection: 'asc' },
               { key: 'representante', label: 'Nombre Representante', sortable: false, sortDirection: 'asc' },
               { key: 'actions', label: 'Acciones', sortable: false }
            ],
            modalEliminar: '',
            nameState: null,
            nombre: '',
         }
      },
      mounted () {
         this.cargarRepres ()
         this.totalRows = this.repres.length
      },
      methods: {
         cargarRepres () {
            axios({url: 'https://api.asein.com/cargar-repres', method: 'POST' })
            .then(resp => {
               this.repres = resp.data.repres
            })
            .catch(err => {
               console.log(err)
            })
         },
         eliminarRepre (id, repre) {
            this.modalEliminar = ''
            this.$bvModal.msgBoxConfirm('¿Está seguro que desea eliminar al representante ' + repre + '?', {
               title: 'Confirmar borrado',
               size: 'sm',
               buttonSize: 'sm',
               okVariant: 'danger',
               okTitle: 'Eliminar',
               cancelTitle: 'Cancelar',
               footerClass: 'p-2',
               centered: true
            })
            .then(value => {
               if (value == true) {
                  axios({url: 'https://api.asein.com/eliminar-repre?id=' + id, method: 'POST' })
                  .then(resp => {
                     console.log (resp.data)
                     this.cargarRepres ()
                  })
                  .catch(err => {
                     console.log(err)
                  })
               }
            })
            .catch(err => {
               console.log (err)
            })
         },
         checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
         },
         resetModal() {
            this.nombre = ''
            this.nameState = null
         },
         handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
         },
         handleSubmit() {
            if (!this.checkFormValidity()) {
               return
            }
            axios({url: 'https://api.asein.com/nuevo-repre?nombre=' + this.nombre, method: 'POST' })
               .then(resp => {
                  console.log (resp.data)
                  this.cargarRepres ()
               })
               .catch(err => {
                  console.log(err)
               })
            this.$nextTick(() => {
               this.$bvModal.hide('modal-nuevo-repre')
            })
         }
      }
   }
</script>
