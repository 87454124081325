<template>
   <div id="app">
      <b-container v-if="(($router.currentRoute.name != 'login') && ($router.currentRoute.name != 'users'))" fluid class="px-0">
         <b-card overlay header-tag="header" img-src="/assets/img/bg-asein.jpg" img-alt="Background Escobilla ASEIN" text-variant="white" :title="$t('header-title')">
            <template #header>
               <nav-bar></nav-bar>
            </template>
            <router-view @getData="getData"/>
         </b-card>
      </b-container>
      <b-container v-else>
         <router-view @getData="getData"/>
      </b-container>

      <msg-info v-if="mostrarMsg" :msg="msg" />

      <card v-if="items.length > 0 && $store.getters.isLoggedIn && $route.name != 'users'" :items="items"/>

   </div>
</template>

<script>
   import navBar from '@/components/NavBar.vue'
   import msgInfo from '@/components/MsgInfo.vue'
   import card from '@/components/Card.vue'
   export default {
      data () {
         return {
            mostrarMsg: false,
            msg: '',
            items: []
         }
      },
      methods: {
         getData(data) {
            this.mostrarMsg = data['mostrarMsg']
            this.msg = data['msg']
            this.items = data['items']
         }
      },
      components: { msgInfo, card, navBar }
   }
</script>

<style>
   @font-face {
      font-family: AvenirNextLTPro-Regular;
      src: url('@/assets/fonts/AvenirNextLTPro-Regular.otf');
   }
   @font-face {
      font-family: AvenirNextLTPro-Bold;
      src: url('@/assets/fonts/AvenirNextLTPro-Bold.otf');
   }
   * {
      font-family: AvenirNextLTPro-Regular;
   }
   h4.card-title {
      margin-top: 17.5rem;
      font-family: 'AvenirNextLTPro-Bold';
      font-size: 40px;
      text-transform: uppercase;
      text-align: center;
   }
   .input-group {
      background-color: rgb(211 211 210 / 30%);
   }
   .input-search {
      text-align: center;
      font-size: 25px!important;
      color: #bbb;
   }
   .select-tipo-busqueda {
      font-family: 'AvenirNextLTPro-Bold';
      background-color: red;
      border-color: red;
      color: #fff;
      border-radius: 0;
      padding: 16px;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
   }
   .btn-buscar {
      border-radius: 0px!important;
      text-transform: uppercase;
      padding: 14px 50px!important;
      font-family: 'AvenirNextLTPro-Bold'!important;
      background-color: red!important;
      margin-left: -0.5rem !important;
   }
   .btn-buscar:hover {
      background-color: #000!important;
      color: #fff!important;
      border-color: #000!important;
   }
   .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 1.5rem;
   }
   .bg-ref-asein {
      background-color: #9EA374;
      color: #fff;
   }
   .bg-ref-fabricante {
      background-color: #BEC19F;
   }
   .bg-dimensiones {
      background-color: #DEE0CC;
   }
   .img-thumbnail {
      background-color: transparent;
      border: none;
   }
   button.btn.btn-outline-secondary {
      background-color: #fff;
      border: 1px solid rgb(206, 212, 218);
      border-left: none;
      border-radius: 0;
      padding: 0.65rem;
   }
   button.btn.btn-outline-secondary:hover, button.btn.btn-outline-secondary:active, button.btn.btn-outline-secondary:focus {
      color: #6C757D;
   }
   .select-marca {
      padding: 0.9rem!important;
   }
   img.card-img {
      min-height:520px!important;
   }
   .card-header {
      padding: 0!important;
      border: none!important;
      background-color: unset!important;
      z-index: 2;
   }
   .position-relative {
      margin-top: -62px;
      z-index: 1;
   }
   span, .dropdown-toggle::after {
      font-family: AvenirNextLTPro-Regular;
      color: #fff;
   }
   .dropdown-item.active, .dropdown-item:active {
      background-color: var(--bs-dropdown-link-hover-bg)!important;
      color: var(--bs-dropdown-link-color)!important;
   }
</style>
