import es from './es.json'
import en from './en.json'
import it from './it.json'
import fr from './fr.json'

export const defaultLocale = 'es'

export const languages = {
   es: es,
   en: en,
   it: it,
   fr: fr
}