import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import { languages } from './i18n/index.js'
import { defaultLocale } from './i18n/index.js'
const messages = Object.assign(languages)

var i18n = new VueI18n({
   locale: defaultLocale,
   fallbackLocale: 'de',
   messages
})


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
   router,
   store,
   i18n,
   render: h => h(App)
}).$mount('#app')
