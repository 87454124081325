<template>
    <b-modal id="modalMedidas" title="Medidas Escobilla" ok-only ok-variant="danger" ok-title="Cerrar">
        <div class="modal-body">
            <div class="text-center">
               <img src="@/assets/img/medidas-escobillas.png" alt="Medidas Escobillas" width="250">
            </div>
         </div>
    </b-modal>
</template>

<script>
   export default {
      name: 'ModalMedidas',
   }
</script>